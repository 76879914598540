<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        {{ $t('InfirmaryListUser') }}
      </v-card-title>
      <v-card-text>
        <v-form>
          <div class="d-flex">
            <v-text-field
              v-model="search"
              :label="$t('Search')"
              outlined
              dense
              :placeholder="$t('Search')"
              :append-icon="mdiMagnify"
              hide-details="auto"
              class="mb-4"
            ></v-text-field>
          </div>
          <div class="d-flex justify-space-between">
            <v-btn @click="handleGetData()">
              {{ $t('RefreshData') }}
            </v-btn>
            <v-btn
              color="primary"
              :to="{name: 'staff-town-infirmary-checkin'}"
            >
              {{ $t('CheckIn') }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="isBusy"
    >
      <v-list v-if="getWaitList.length > 0">
        <template v-if="getLoadingWait">
          <v-progress-linear
            indeterminate
            color="primary"
          ></v-progress-linear>
        </template>
        <template
          v-for="(item, index) in getWaitList"
        >
          <v-list-item

            :key="item.id_check_in"
            :to="{name: 'staff-town-infirmary-checkup', params: {id: `${item.id_user}-${item.id_check_in}`}}"
          >
            <v-list-item-avatar>
              <v-img
                alt
                :src="item.face_image_url || require('@/assets/images/avatars/1.png')"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ item.full_name || '-' }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text--primary">
                  {{ item.phone || '-' }}
                </span><br />
                <span class="text-body-2">{{ $t('CheckIn') }}: {{ formatDate(item.check_in_at*1000) }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
              >
                <v-icon>
                  {{ mdiClipboardCheckOutline }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < getWaitList.length - 1"
            :key="`divider-${item.id_check_in}`"
            :inset="item.inset"
          ></v-divider>
        </template>
      </v-list>
      <v-card-text
        v-else
      >
        {{ $t('NoData') }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Bus from '@/utils/bus'
import { mdiClipboardCheckOutline, mdiMagnify } from '@mdi/js'
import _ from 'lodash'

// import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      search: '',
      mdiClipboardCheckOutline,
      mdiMagnify,
      busy: false,
      page: 1,
      limit: 10,
    }
  },
  computed: {
    ...mapGetters('town', ['getWaitList', 'getLoadingWait', 'getIsMore']),
    paramsWaitingList() {
      return {
        keyword: this.search,
        type: 'waiting',
        page: this.page,
        limit: this.limit,
      }
    },
    isBusy() {
      return this.getLoadingWait || !this.getIsMore
    },
  },
  watch: {
    search() {
      this.handleGetData()
    },
  },
  created() {
    this.handleGetData()
    Bus.$on('haveMessage', this.handleMsg)
  },
  destroyed() {
    Bus.$off('haveMessage', this.handleMsg)
  },

  methods: {
    ...mapActions('town', ['fetchWaitList', 'loadMoreWaitList']),
    handleMsg(codeEvent) {
      if (codeEvent === 'town_infirmary_001') {
        this.handleGetData()
      }
    },
    handleGetData: _.debounce(function() {
      this.fetchWaitList({ ...this.paramsWaitingList, page: 1 })
    }, 500),
    loadMore: _.debounce(function() {
      // this.busy = true
      this.loadMoreWaitList({ ...this.paramsWaitingList, page: this.page + 1 }).then(() => {
        this.page += 1
      })
    }, 500),
  },
}
</script>
